import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import "tailwindcss/tailwind.css";
import "../../fonts/custom-font.css";

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

function SEO({
  meta = [],
  title = "O melhor da saúde mental",
  keywords = [
    "Pensamental",
    "Saúde Mental",
    "Mentalidade Saudável",
    "Autoconhecimento",
    "Autoconfiança",
    "Autoestima",
    "Inteligência Emocional",
    "Desenvolvimento Pessoal",
    "Crescimento Profissional",
    "Controle Emocional",
    "Magnetismo Relacional",
    "Resolução de Problemas",
    "Independência",
    "Assertividade",
    "Tomada de Decisões",
    "Reaja melhor às exigências da vida",
    "Aprenda a viver muito bem",
    "Saiba como você funciona",
    "Não tenha medo de arriscar",
    "Tenha a melhor visão de você",
    "Identifique e lide com as emoções",
    "Cuide da sua mente agora",
    "Aprenda a lidar com suas questões emocionais e de desenvolvimento humano.",
    "Aqui proporcionamos à vocês tudo aquilo que o sistema falhou em te ensinar sobre saúde mental.",
    "Psicólogos",
    "Psiquiatras",
    "Enfermeiros Psiquiátricos",
    "Terapeutas Ocupacionais",
    "Acompanhantes Terapêuticos",
    "Musicoterapeutas",
    "Terapeutas",
    "Psicoterapeutas",
    "Psicanalistas",
    "Neurologistas",
    "Neuropsicólogos",
    "Neuropsiquiatras",
  ],
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { description } = data.site.siteMetadata;
        const siteName = data.site.siteMetadata.title;
        const { author } = data.site.siteMetadata;
        return (
          <Helmet
            htmlAttributes={{
              lang: "pt",
            }}
            title={siteName}
            titleTemplate={`%s ${title}`}
            link={[
              {
                rel: "canonical",
                href: "https://www.pensamental.com",
              },
            ]}
            meta={[
              {
                name: `description`,
                content: "Aprenda a lidar com suas questões emocionais e de desenvolvimento humano. Aqui proporcionamos à vocês tudo aquilo que o sistema falhou em te ensinar sobre saúde mental.",
              },
              {
                name: `facebook-domain-verification`,
                content: process.env.REACT_APP_FB_PIXEL_VERIFY ?? "bs8ayb3nra4hcbftvmd0pc8zhxnb45",
              },
              {
                property: `og:locale`,
                content: "pt",
              },
              {
                property: `og:title`,
                content: "Pensamental | O melhor da saúde mental",
              },
              {
                property: `og:description`,
                content: "Aprenda a lidar com suas questões emocionais e de desenvolvimento humano. Aqui proporcionamos à vocês tudo aquilo que o sistema falhou em te ensinar sobre saúde mental.",
              },
              {
                property: `og:url`,
                content: "https://www.pensamental.com/",
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:site_name`,
                content: "www.pensamental.com",
              },
              {
                property: `og:image`,
                content: "https://user-images.githubusercontent.com/8363610/117083640-890d3b00-ad1b-11eb-8e60-fdd3dec19099.png",
              },
              {
                property: `og:image:secure_url`,
                content: "https://user-images.githubusercontent.com/8363610/117083640-890d3b00-ad1b-11eb-8e60-fdd3dec19099.png",
              },
              {
                property: `og:image:width`,
                content: "1200",
              },
              {
                property: `og:image:height`,
                content: "650",
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
              {
                name: `twitter:image`,
                content: "https://user-images.githubusercontent.com/8363610/117083640-890d3b00-ad1b-11eb-8e60-fdd3dec19099.png",
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : [],
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

export default SEO;
