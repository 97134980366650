import tw from "tailwind-styled-components";
import { Link as LinkGatsby } from "gatsby";

export const Footer = tw.footer`
  w-full 
  flex 
  justify-center
`;

export const Content = tw.div`
  w-full
  flex
  flex-col
  justify-center
  items-center
  max-w-screen-xl
  text-center
`;

export const Divider = tw.hr`
  w-full
`;

export const InfoArea = tw.div`
  pt-4
  flex 
  flex-col xl:flex-row 
  w-full
  px-4
  items-center
  justify-center
`;

export const MenuLink = tw.a`
  no-underline 
  text-white 
`;

export const Logo = tw.img`
  w-60 
  transition duration-300 ease-in-out 
  cursor-pointer 
  transform 
  xs:w-1

  hover:scale-105
`;

export const InfoText = tw.div`
  text-white 
  text-md 
  font-comfortaa
`;

export const InfoIcon = tw.span`
  mx-2
  text-xl 
  text-white 
  transition 
  duration-300 
  ease-in-out 
  transform 
  cursor-pointer 
  hover:text-blue-pensamental-light
`;

export const Link = tw(LinkGatsby)`
  font-comfortaa
  text-white
  transform 
  transition duration-300 ease-in-out 
  hover:text-blue-pensamental-light
  hover:underline
`;

export const GooglePlayAnchor = tw.a`
  w-100
  flex
  content-center
  justify-center
  items-center
`;

export const GooglePlay = tw.img`
  w-48
  transition duration-300 ease-in-out 
  cursor-pointer 
  transform 
  xs:w-1
  mb-2

  hover:scale-105
`;
