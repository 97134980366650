import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useScrollPosition } from "../../hooks/useScroll";

import { SideBar, MenuMobileArea, Hamburger, Header, MenuItemPseudo, Content, Logo, MenuArea, MenuItem, SideBarContent, SideBarItemAnchor, MenuLinkAnchor } from "./style";
import LogoImage from "../../images/logo.webp";

export default function HeaderComponent({ blueDefault }) {
  const { scrollPosition } = useScrollPosition();
  const isScrollingDown = scrollPosition > 40;

  return (
    <Header $blueDefault={blueDefault} $isScrollingDown={isScrollingDown}>
      <Content>
        <MenuLinkAnchor href="/">
          <Logo alt="Pensamental - Logo" src={LogoImage} />
        </MenuLinkAnchor>
        <MenusMobile />
        <MenusDesktop />
      </Content>
    </Header>
  );
}

function MenusDesktop() {
  const [isHoveringMenuHome, setIsHoveringMenuHome] = useState(false);
  const [isHoveringMenuMentoria, setIsHoveringMenuMentoria] = useState(false);
  const [isHoveringMenuEntrar, setIsHoveringMenuEntrar] = useState(false);

  return (
    <MenuArea>
      <MenuLinkAnchor href="/">
        <MenuItem onMouseEnter={() => setIsHoveringMenuHome(true)} onMouseLeave={() => setIsHoveringMenuHome(false)}>
          <MenuItemPseudo $isHover={isHoveringMenuHome} />
          Início
        </MenuItem>
      </MenuLinkAnchor>
      <MenuLinkAnchor href="/form">
        <MenuItem onMouseEnter={() => setIsHoveringMenuMentoria(true)} onMouseLeave={() => setIsHoveringMenuMentoria(false)}>
          <MenuItemPseudo $isHover={isHoveringMenuMentoria} />
          Mentoria
        </MenuItem>
      </MenuLinkAnchor>
      <MenuLinkAnchor rel="noreferrer" target="_blank" href="https://escola-pensamental.memberkit.com.br/users/sign_in">
        <MenuItem onMouseEnter={() => setIsHoveringMenuEntrar(true)} onMouseLeave={() => setIsHoveringMenuEntrar(false)}>
          <MenuItemPseudo $isHover={isHoveringMenuEntrar} />
          Login
        </MenuItem>
      </MenuLinkAnchor>
    </MenuArea>
  );
}

function MenusMobile() {
  const [isSideBarShown, setIsSideBarShown] = useState(false);

  return (
    <MenuMobileArea>
      <Hamburger onClick={() => setIsSideBarShown(!isSideBarShown)} $isSideBarShown={isSideBarShown}>
        <div className="tham-box">
          <div className={`bg-white tham-inner ${isSideBarShown ? "opacity-70" : ""}`} />
        </div>
      </Hamburger>

      <OutsideClickHandler onOutsideClick={() => setIsSideBarShown(false)}>
        <SideBar $isShown={isSideBarShown}>
          {isSideBarShown ? (
            <SideBarContent>
              <SideBarItemAnchor href="/" $hasBorder>
                Início
              </SideBarItemAnchor>
              <SideBarItemAnchor $hasBorder href="/form">
                Mentoria
              </SideBarItemAnchor>
              <SideBarItemAnchor rel="noreferrer" target="_blank" href="https://escola-pensamental.memberkit.com.br/users/sign_in">
                Login
              </SideBarItemAnchor>
            </SideBarContent>
          ) : null}
        </SideBar>
      </OutsideClickHandler>
    </MenuMobileArea>
  );
}
