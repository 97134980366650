import React from "react";

import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Footer as SCFooter, Content, Divider, InfoArea, InfoText, InfoIcon, Link, GooglePlayAnchor, GooglePlay } from "./style";
import GooglePlayImage from "../../images/google-play-badge.webp";

export default function Footer({ hideFooter }) {
  return (
    <SCFooter>
      <Content>
        {!hideFooter && <Divider />}

        <InfoArea>
          <div className="flex flex-row mb-0 sm:mb-2 mt-0 sm:mt-2">
            <Link to="/privacidade">Privacidade</Link>
          </div>
        </InfoArea>

        <div>
          <GooglePlayAnchor target="_blank" href="https://play.google.com/store/apps/details?id=com.pensamental.escola">
            <GooglePlay alt="Disponível no Google Play" src={GooglePlayImage} />
          </GooglePlayAnchor>

          <InfoText>{`@ Pensamental ${new Date().getFullYear()} | Todos direitos reservados.`}</InfoText>

          <div className="mt-4 mb-6 justify-center flex">
            <InfoIcon style={{ maxWidth: "20px", maxHeight: "20px" }} role="button" onClick={() => window.open("https://www.instagram.com/pensamental", "_blank")}>
              <FontAwesomeIcon style={{ maxWidth: "20px", maxHeight: "20px" }} icon={faInstagram} />
            </InfoIcon>
            <InfoIcon style={{ maxWidth: "20px", maxHeight: "20px" }} role="button" onClick={() => window.open("https://facebook.com/pensamental", "_blank")}>
              <FontAwesomeIcon style={{ maxWidth: "20px", maxHeight: "20px" }} icon={faFacebook} />
            </InfoIcon>
          </div>
        </div>
      </Content>
    </SCFooter>
  );
}
