import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Link } from "gatsby";

export const Header = tw.header`
  fixed 
  w-full 
  flex 
  justify-center
  z-10
  transition duration-300 
  transform

  ${(props) => props.$isScrollingDown && !props.$blueDefault && "bg-blue-pensamental-darkest"}
  ${(props) => props.$isScrollingDown && props.$blueDefault && "bg-blue-pensamental-dark"}
`;

export const Content = tw.div`
  w-full
  max-w-screen-xl	
  grid 
  grid-flow-col 
  justify-between 
  content-center 
  justify-center 
  py-3
  px-6 sm:px-2
`;

export const Logo = tw.img`
  w-60 
  transition duration-300 ease-in-out 
  cursor-pointer 
  transform 
  xs:w-1

  hover:scale-105
`;

export const MenuLink = tw(Link)`
  no-underline 
  text-white 
`;

export const MenuLinkAnchor = tw.a`
  no-underline 
  text-white 
`;

export const MenuArea = tw.nav`
  grid 
  grid-flow-col 
  grid-cols-2 
  gap-0 md:gap-4 
  items-center

  hidden sm:grid
`;

export const MenuItem = tw.ul`
  relative 
  w-28
  text-center 
  font-comfortaa 
  cursor-pointer 
  px-0 py-1 
  transition-colors

  hover:text-blue-pensamental-light 
`;

export const MenuItemPseudo = tw.div`
  absolute 
  w-0
  h-0.5
  top-0 
  left-0 
  m-0 
  bg-blue-pensamental-light
  opacity-0
  transition-width duration-300 ease
  
  ${(props) => props.$isHover && "w-28 opacity-100"}
`;

export const MenuMobileArea = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  &,
  div {
    align-content: center;
    align-items: center;
  }
`;

export const Hamburger = tw.div`
  visible sm:invisible
  tham tham-e-squeeze tham-w-6 
  ${(props) => props.$isSideBarShown && "tham-active"}
`;

export const SideBar = tw.div`
  absolute
  z-10
  h-screen
  w-0
  bg-blue-pensamental-darkest
  top-0
  left-0
  opacity-0
  transition-width duration-300 ease
  text-white

  ${(props) => props.$isShown && "w-4/5 opacity-100"}
`;

export const SideBarContent = tw.nav`
  p-10
  grid
`;

export const SideBarItem = tw(Link)`
  text-white
  font-comfortaa
  text-lg
  py-3

  ${(props) => props.$hasBorder && "border-b border-white"}
`;

export const SideBarItemAnchor = tw.a`
  text-white
  font-comfortaa
  text-lg
  py-3

  ${(props) => props.$hasBorder && "border-b border-white"}
`;
